import { AnimatePresence, motion } from "framer-motion";
import { DotLottiePlayer } from "@dotlottie/react-player";
import "@dotlottie/react-player/dist/index.css";

import "./styles.css";

import Avatar from "../../../assets/images/avatar.png";
import { useEffect, useState } from "react";

const iconList = [
  "🧑🏻‍💻️",
  "🎨",
  "🎮",
  "👩🏻‍❤️‍👨🏻",
  "💪🏻",
  "☕️",
  "🐶🐱",
  "🎻",
  "🎧",
  "🤓",
];
const MeSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % iconList.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <motion.div 
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.6, ease: "anticipate" }}
      viewport={{ margin: "-120px" }} 
      whileInView={
        { scale: 1.1 }
      }
      className="font-bold text-4xl mt-6">I'm Azat ESER</motion.div>

      <div className="mt-8 z-10">
        <motion.div 
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.6, delay: 0.3, ease: "anticipate" }}
        className="w-40 h-40 relative group">
          <img
            className=" w-full h-full object-cover group-hover:scale-110 transition-all duration-1000"
            src={Avatar}
            alt="Azat ESER Avatar"
          />
          <div className="shine group-hover:w-[8.5rem] group-hover:h-[8.5rem] transition-all"></div>
          <div className="absolute w-24 h-24 top-8 left-8 animate-ping group-hover:animate-none  bg-[#312645] rounded-full -z-10"></div>
          <div className="absolute w-32 h-32 top-4 left-4  animate-avatar  bg-[#312645] rounded-full -z-10"></div>
          <motion.ul 
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6, delay: 0.7, ease: "easeInOut" }}
          className="absolute -bottom-3 left-[40%] -translate-x-1/2  text-4xl">
            <AnimatePresence initial={false} exitBeforeEnter={false}>
              <motion.li
                key={currentIndex}
                initial={{ opacity: 0, y: 40, position: "absolute" }}
                animate={{ opacity: 1, y: 0, position: "relative" }}
                exit={{ opacity: 0, y: -20, position: "absolute" }}
                transition={{ duration: 1, ease: "easeInOut" }}
                style={{
                  display: "flex",
                  flexDirection: "col",
                  alignItems: "center",
                  justifyContent: "center",
                  userSelect: "none",
                }}
              >
                {iconList[currentIndex]}
              </motion.li>
            </AnimatePresence>
          </motion.ul>
        </motion.div>
      </div>
      <div className="relative w-full h-[500px] flex flex-col items-center">
        <div
          className="flex flex-col lg:text-8xl md:text-7xl transition-all text-6xl w-max text-white font-inter z-10 select-none  font-black text-center mt-16 mix-blend-overlay opacity-80"
          style={{ textShadow: "0px 0px 0px #ddd7b0" }}
        >
          <motion.span 
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20}}
          transition={{ duration: 0.6, delay: 0.7, ease: "anticipate" }}
          >MID</motion.span>
          <motion.span 
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.8, delay: 0.9, ease: "anticipate" }}>FRONT-END</motion.span>
          <motion.span 
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 1, delay: 1.2, ease: "anticipate" }}>DEVELOPER</motion.span>
        </div>
        <div
          style={{
            backgroundColor: "rgb(18, 17, 32)",
            height: "100%",
            width: "100%",
            opacity: 1,
            overflow: "hidden",
            position: "absolute",
            zIndex: -999,
            left: 0,
            bottom: 0,
          }}
        >
          <div
            style={{
              opacity: 1,
              height: "349px",
              left: "calc(50.069444444444464% - 1254px / 2)",
              overflow: "visible",
              position: "absolute",
              top: "calc(49.83660130718957% - 349px / 2)",
              width: "1254px",
            }}
          >
            <div
              style={{
                background:
                  "radial-gradient(50% 50% at 50% 50%, hsl(263, 82%, 35%) 0%, rgba(87, 57, 53, 0) 100%)",
                filter: "blur(64px)",
                WebkitFilter: "blur(64px)",
                opacity: "0.9",
                bottom: 0,
                flex: "none",
                left: 0,
                overflow: "visible",
                position: "absolute",
                top: 0,
                width: "838px",
                height: "349px",
              }}
            />
            <div
              className="framer-inag7a"
              style={{
                background:
                  "radial-gradient(50% 50% at 50% 50%, hsl(11, 32%, 44%) 0%, rgba(87, 57, 53, 0) 100%)",
                filter: "blur(64px)",
                WebkitFilter: "blur(64px)",
                opacity: "0.95",
                bottom: 0,
                flex: "none",
                overflow: "visible",
                position: "absolute",
                right: 0,
                top: 0,
                width: "864px",
                height: "349px",
              }}
            />
            <div
              style={{
                background:
                  "radial-gradient(50% 50% at 50% 50%, hsl(243, 50%, 48%) 0%, rgba(87, 57, 53, 0) 100%)",
                filter: "blur(64px)",
                WebkitFilter: "blur(64px)",
                opacity: "0.7",
                flex: "none",
                height: "295px",
                left: "calc(49.9202551834131% - 324px / 2)",
                overflow: "visible",
                position: "absolute",
                top: "calc(49.85673352435532% - 295px / 2)",
                width: "324px",
              }}
            />
          </div>
        </div>
      </div>
      <DotLottiePlayer
        src="https://framerusercontent.com/modules/assets/6Hwubvigct3R3CXW9AZ1cvnVgrs~HSi6JbhLs8FDE1I8AFixXTRDU41ZWt4z5-iz0-4-2gM.zip"
        autoplay
        loop
        background="rgba(0, 85, 255, 0)"
        speed="1"
        direction="1"
        style={{
          width: "100%",
          height: "100%",
          opacity: 0.1,
          transform: "perspective(1200px)",
          position: "absolute",
          bottom: -280,
          left: 0,
          zIndex: 0,
        }}
      />
    </div>
  );
};

export default MeSection;
