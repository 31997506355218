import React from "react";

function PenContactSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="255"
      height="299"
      fill="none"
        viewBox="0 0 255 299"
        {...props}
    >
      <rect
        width="60.778"
        height="26.048"
        x="99.367"
        y="244.994"
        fill="url(#svg-518822946_6687_a)"
        fillOpacity="0.18"
        rx="5.788"
      ></rect>
      <path
        fill="url(#svg-518822946_6687_b)"
        fillOpacity="0.18"
        fillRule="evenodd"
        d="M127.183 129.227L82.25 204.49a3.858 3.858 0 00.103 4.118l24.048 36.074h44.063l23.505-33.968a7.718 7.718 0 00.237-8.42l-44.713-73.067h-2.31zm1.575 88.755c6.66 0 12.059-5.4 12.059-12.06s-5.399-12.059-12.059-12.059c-6.66 0-12.059 5.399-12.059 12.059 0 6.66 5.399 12.06 12.059 12.06z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#353444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M150.395 245.053l22.636-33.954c1.265-1.897 1.897-2.845 2.156-3.873a6.163 6.163 0 00.055-2.775c-.219-1.037-.813-2.01-2.002-3.955l-44.617-73.01m0 0l-44.617 73.01c-1.189 1.945-1.783 2.918-2.002 3.955a6.159 6.159 0 00.055 2.775c.26 1.028.892 1.976 2.156 3.873l22.636 33.954m21.772-117.567v66.064m-20.628 77.629h41.256c3.449 0 5.173 0 6.49-.671a6.15 6.15 0 002.691-2.691c.672-1.317.672-3.042.672-6.49v-6.421c0-3.449 0-5.173-.672-6.49a6.15 6.15 0 00-2.691-2.691c-1.317-.672-3.041-.672-6.49-.672h-41.256c-3.448 0-5.173 0-6.49.672a6.152 6.152 0 00-2.691 2.691c-.671 1.317-.671 3.041-.671 6.49v6.421c0 3.448 0 5.173.67 6.49a6.153 6.153 0 002.692 2.691c1.317.671 3.042.671 6.49.671zm29.337-56.606c-4.81 4.81-12.608 4.81-17.418 0-4.809-4.81-4.809-12.608 0-17.418 4.81-4.809 12.608-4.809 17.418 0 4.809 4.81 4.809 12.608 0 17.418z"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_c)"
        d="M4.6 118.512S69.255 52.7 130.446 108.121s122.382-11.545 122.382-11.545"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_d)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 110.59s64.655-65.812 125.846-10.391 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_e)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 103.59s64.655-65.812 125.846-10.391 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_f)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 96.59s64.655-65.812 125.846-10.391 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_g)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 89.59s64.655-65.812 125.846-10.391 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_h)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 82.59s64.655-65.812 125.846-10.391 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_i)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 75.59S69.255 9.778 130.446 65.199s122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_j)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 68.59S69.255 2.778 130.446 58.199s122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_k)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 61.59S69.255-4.222 130.446 51.199s122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_l)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 54.59s64.655-65.812 125.846-10.391c61.191 55.42 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_m)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 47.59s64.655-65.812 125.846-10.391c61.191 55.42 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_n)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 40.59s64.655-65.812 125.846-10.391c61.191 55.42 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_o)"
        strokeOpacity="0.95"
        strokeWidth="0.5"
        d="M4.6 33.59s64.655-65.812 125.846-10.391c61.191 55.42 122.382-11.546 122.382-11.546"
      ></path>
      <path
        stroke="url(#svg-518822946_6687_p)"
        d="M90.052 58.216l79.664 102.755"
      ></path>
      <path
        fill="#121120"
        stroke="#31303D"
        d="M.5 114.134h8.236v8.236H.5z"
      ></path>
      <path
        fill="#181726"
        stroke="#31303D"
        d="M125.201 104.897h8.236v8.236h-8.236z"
      ></path>
      <path
        fill="#121120"
        stroke="#31303D"
        strokeWidth="1.929"
        d="M245.738 96.127h7.307v7.307h-7.307z"
      ></path>
      <circle cx="90.069" cy="58.216" r="2.309" fill="#353441"></circle>
      <circle cx="168.579" cy="159.819" r="2.309" fill="#353441"></circle>
      <defs>
        <linearGradient
          id="svg-518822946_6687_a"
          x1="129.273"
          x2="124.49"
          y1="270.077"
          y2="242.994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B5874"></stop>
          <stop offset="1" stopColor="#121120"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_b"
          x1="118.146"
          x2="118.056"
          y1="244.994"
          y2="180.357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EADCFF" stopOpacity="0.25"></stop>
          <stop offset="1" stopColor="#121120"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_c"
          x1="187.763"
          x2="186.239"
          y1="100.472"
          y2="134.714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#353441"></stop>
          <stop offset="1" stopColor="#292835"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_d"
          x1="-3.5"
          x2="3.818"
          y1="109.907"
          y2="83.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_e"
          x1="-3.5"
          x2="3.818"
          y1="102.907"
          y2="76.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_f"
          x1="-3.5"
          x2="3.818"
          y1="95.907"
          y2="69.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_g"
          x1="-3.5"
          x2="3.818"
          y1="88.907"
          y2="62.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_h"
          x1="-3.5"
          x2="3.818"
          y1="81.907"
          y2="55.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_i"
          x1="-3.5"
          x2="3.818"
          y1="74.907"
          y2="48.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_j"
          x1="-3.5"
          x2="3.818"
          y1="67.907"
          y2="41.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_k"
          x1="-3.5"
          x2="3.818"
          y1="60.907"
          y2="34.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_l"
          x1="-3.5"
          x2="3.818"
          y1="53.907"
          y2="27.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_m"
          x1="-3.5"
          x2="3.818"
          y1="46.907"
          y2="20.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_n"
          x1="-3.5"
          x2="3.818"
          y1="39.907"
          y2="13.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_o"
          x1="-3.5"
          x2="3.818"
          y1="32.907"
          y2="6.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#121120"></stop>
          <stop offset="1" stopColor="#2B2A37"></stop>
        </linearGradient>
        <linearGradient
          id="svg-518822946_6687_p"
          x1="148.835"
          x2="125.503"
          y1="92.074"
          y2="164.388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#353441"></stop>
          <stop offset="1" stopColor="#292835"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PenContactSVG;
