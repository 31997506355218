import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const ProjectItem = ({
  images,
  title,
  description,
  profile,
  link,
  demoLink,
}) => {
  const [hovered, setHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    let intervalId;
    if (hovered) {
      intervalId = setInterval(() => {
        rotateImages();
      }, 500);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered, currentImageIndex]);

  const handleHover = () => {
    setHovered(!hovered);
    setCurrentImageIndex(0);
  };

  const rotateImages = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
  };

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      className="cursor-pointer bg-[#82828a33] group rounded-lg p-5 hover:scale-[1.02] transition-all"
    >
      <div className="w-full h-[309px] bg-white">
        <img
          className="w-full h-full object-contain"
          src={images[currentImageIndex]}
          alt=""
        />
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 rounded-full">
            <img className="rounded-full" src={profile} alt="" />
          </div>
          <div>
            <h3 className="text-[#f5f5f5] font-inter font-semibold">{title}</h3>
            <p className="text-[#9e9e9e] font-inter font-medium text-sm group-hover:text-primary transition-all">
              {description}
            </p>
          </div>
        </div>
        {demoLink && (
          <a
            target="_blank"
            rel="noreferrer"
            href={demoLink}
            className="group/button flex items-center gap-2 bg-[#f5f5f5] text-[#282735] font-inter text-sm px-3 py-2  rounded-lg hover:bg-primary hover:text-[#f5f5f5]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              className="group/button-hover:fill-[#f5f5f5] fill-current"
            >
              <path
                d="M40.96 4.98a2 2 0 00-.22.02H28a2 2 0 100 4h8.172L22.586 22.586a2 2 0 102.828 2.828L39 11.828V20a2 2 0 104 0V7.246a2 2 0 00-2.04-2.266zM12.5 8C8.383 8 5 11.383 5 15.5v20c0 4.117 3.383 7.5 7.5 7.5h20c4.117 0 7.5-3.383 7.5-7.5V26a2 2 0 10-4 0v9.5c0 1.947-1.553 3.5-3.5 3.5h-20A3.483 3.483 0 019 35.5v-20c0-1.947 1.553-3.5 3.5-3.5H22a2 2 0 100-4h-9.5z"
                transform="matrix(.53 0 0 .53 12 12) translate(-24.01 -23.99)"
              ></path>
            </svg>{" "}
            View Demo
          </a>
        )}
      </div>
    </a>
  );
};

export default ProjectItem;
