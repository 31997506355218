import React from "react";

function Logo({width, height, color, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "302.52"}
      height={height || "226.226"}
      viewBox="0 0 302.52 226.226"
      {...props}
    >
      <g>
        <path
          fill={color || "#fff"}
          fillRule="evenodd"
          d="M119.677 219.974l6.67-15.57 58.707-.076L235.302 96.91l-60.16-.417v-.142H123.83l-20.237-.002-33.817-76.31H30.9l69.473 155.386 29.802-64.13H150L100.77 218.997 4.252 4.442l75.561-.16 35.997 76.294 16.203.184v.07h6.175l5.6.064.03-.064h39.509v-.05l78.301.11-62.136 138.933zm32.248-156.867l26.737-58.446 117.606-.409-23.196 50.276-18.078.023 14.879-34.547-78.79-.046-19.078 43.15z"
          transform="matrix(1 0 0 -1 1 225.226)"
        ></path>
        <path
          fill="none"
          stroke={color || "#fff"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8.504"
          d="M120.677 5.252l6.67 15.57 58.707.076 50.248 107.418-60.16.418v.141H124.83l-20.237.002-33.817 76.31H31.9L101.373 49.8l29.802 64.13H151L101.77 6.23 5.252 220.784l75.561.16 35.997-76.294 16.203-.184v-.07h6.175l5.6-.064.03.064h39.509v.05l78.301-.11L200.492 5.403zm32.248 156.867l26.737 58.446 117.606.41-23.196-50.277-18.078-.022 14.879 34.547-78.79.045-19.078-43.15z"
        ></path>
      </g>
    </svg>
  );
}

export default Logo;
