import React from 'react'
import Svayp from './Svayp/Svayp'

const Projects = () => {
  return (
    <>
    <Svayp />
    </>
  )
}

export default Projects