import React from 'react'
import { motion } from 'framer-motion';

const images = [
    "https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=black",
    "https://img.shields.io/badge/TypeScript-007ACC?style=for-the-badge&logo=typescript&logoColor=white",
    "https://img.shields.io/badge/Node.js-43853D?style=for-the-badge&logo=node.js&logoColor=white",
    "https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB",
    "https://img.shields.io/badge/Next-black?style=for-the-badge&logo=next.js&logoColor=white",
    "https://img.shields.io/badge/React_Native-20232A?style=for-the-badge&logo=react&logoColor=61DAFB",
    "https://img.shields.io/badge/Vue.js-35495E?style=for-the-badge&logo=vue.js&logoColor=4FC08D",
    "https://img.shields.io/badge/Tailwind_CSS-38B2AC?style=for-the-badge&logo=tailwind-css&logoColor=white",
    "https://img.shields.io/badge/styled--components-DB7093?style=for-the-badge&logo=styled-components&logoColor=white",
    "https://img.shields.io/badge/MUI-%230081CB.svg?style=for-the-badge&logo=mui&logoColor=white",
    "https://img.shields.io/badge/-AntDesign-%230170FE?style=for-the-badge&logo=ant-design&logoColor=white",
    "https://img.shields.io/badge/Redux-593D88?style=for-the-badge&logo=redux&logoColor=white",
    "https://img.shields.io/badge/React_Router-CA4245?style=for-the-badge&logo=react-router&logoColor=white",
    ];

const images2 = [
    "https://img.shields.io/badge/Socket.io-black?style=for-the-badge&logo=socket.io&badgeColor=010101",
    "https://img.shields.io/badge/MongoDB-4EA94B?style=for-the-badge&logo=mongodb&logoColor=white",
    "https://img.shields.io/badge/postgres-%23316192.svg?style=for-the-badge&logo=postgresql&logoColor=white",
    "https://img.shields.io/badge/-GraphQL-E10098?style=for-the-badge&logo=graphql&logoColor=white",
    "https://img.shields.io/badge/GitHub-100000?style=for-the-badge&logo=github&logoColor=white",
    "https://img.shields.io/badge/GIT-E44C30?style=for-the-badge&logo=git&logoColor=white",
    "https://img.shields.io/badge/firebase-%23039BE5.svg?style=for-the-badge&logo=firebase",
    "https://img.shields.io/badge/Figma-F24E1E?style=for-the-badge&logo=figma&logoColor=white",
    "https://img.shields.io/badge/Adobe%20XD-470137?style=for-the-badge&logo=Adobe%20XD&logoColor=#FF61F6",
    "https://img.shields.io/badge/Dribbble-EA4C89?style=for-the-badge&logo=dribbble&logoColor=white",
    "https://img.shields.io/badge/Framer-black?style=for-the-badge&logo=framer&logoColor=blue",
    "https://img.shields.io/badge/NPM-%23CB3837.svg?style=for-the-badge&logo=npm&logoColor=white",
    
    ];

const Experiences = () => {
  return (
    <div className='md:mt-20' id="skills">
        <div className='text-center font-inter text-white md:px-0 px-12'>
        <motion.div  initial={{ opacity: 0, y:20 }}  viewport={{ margin: "-120px" }} whileInView={{ opacity: 1, y:0 }} className='text-xl font-semibold'>Languages and technologies I am interested in or know</motion.div>
        <motion.div  initial={{ opacity: 0, y:20 }}  viewport={{ margin: "-120px" }} whileInView={{ opacity: .30, y:0 }} className='opacity-30 text-lg'>Description will come</motion.div>
        </div>


        <motion.div  initial={{ opacity: 0}}  viewport={{ margin: "-120px" }} whileInView={{ opacity: 1 }} className=" flex items-center justify-center md:mt-12 mt-6 md:scale-125">
      {/* 1. */}
      <div className="w-[200%] h-14  overflow-hidden relative">
        <div className="w-[200%] flex items-center h-20 justify-around absolute left-0 gap-6 animate-leftslide">
          {images.map((image, index) => {
            return (
                <img key={index} className='opacity-40 hover:opacity-80 cursor-pointer transition-all hover:scale-110 duration-1000' src={image} alt={image} />
            );
          })}
          {images.map((image, index) => {
            return (
                <img key={index} className='opacity-40 hover:opacity-80 cursor-pointer transition-all hover:scale-110 duration-1000' src={image} alt={image} />
            );
          })}
        </div>
      </div>
    </motion.div>
    <motion.div  initial={{ opacity: 0}}  viewport={{ margin: "-20px" }} whileInView={{ opacity: 1 }}  className="flex items-center justify-center  md:scale-125">
      <div className="w-[200%] h-14  overflow-hidden relative">
        <div className="w-[200%]  flex items-center h-20 justify-around absolute left-0 gap-6 animate-rightslide">
          {images2.map((image, index) => {
            return (
                <img key={index} className='opacity-40 hover:opacity-80 cursor-pointer transition-all hover:scale-110 duration-1000' src={image} alt={image} />
            );
          })}
          {images2.map((image, index) => {
            return (
                <img key={index} className='opacity-40 hover:opacity-80 cursor-pointer transition-all hover:scale-110 duration-1000' src={image} alt={image} />
            );
          })}
        </div>
      </div>
    </motion.div>
        </div>
  )
}

export default Experiences