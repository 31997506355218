import React from 'react'

const Footer = () => {
  return (
    <div className='mt-20'>
        

        <div className='text-gray-500 text-center py-4 text-sm'>© Azat ESER {new Date().getFullYear()}</div>
    </div>
  )
}

export default Footer