import React from "react";
import Title from "../../Other/Title";
import ServiceItem from "./components/ServiceItem";
import ProjectItem from "./components/ProjectItem";

const GithubProjects = () => {
  const proects = [
    {
      id: 1,
      images: [
        "/projects/images/fm/cover.png",
        "/projects/images/fm/1.png",
        "/projects/images/fm/2.png",
        "/projects/images/fm/3.png",
        "/projects/images/fm/4.png",
        "/projects/images/fm/5.png",
        "/projects/images/fm/6.png",
        "/projects/images/fm/7.png",
      ],
      image: "/projects/profile/frontend_mentor.png",
      title: "frontendMentor_Projects",
      link: "https://github.com/azateser/frontendMentor_Projects",
      description: "Frontend Mentor Challenge",
    },
    {
      id: 2,
      images: [
        "/projects/images/reactjs/cover.png",
        "/projects/images/reactjs/1.png",
        "/projects/images/reactjs/2.png",
        "/projects/images/reactjs/3.png",
        "/projects/images/reactjs/4.png",
        "/projects/images/reactjs/5.png",
        "/projects/images/reactjs/6.png",
        "/projects/images/reactjs/7.png",
      ],
      image: "/projects/profile/react_js.webp",
      title: "15-React-Project",
      link: "https://github.com/azateser/15-React-Project",
      description: "ReactJS",
    },
    {
      id: 3,
      images: [
        "/projects/images/expo/cover.png",
      ],
      image: "/projects/profile/react_native.webp",
      title: "expo_Projects",
      link: "https://github.com/azateser/expo_Projects",
      description: "React Native",
    },
    {
      id: 4,
      images: [
        "/projects/images/dev/cover.png",
        "/projects/images/dev/1.webp",
        "/projects/images/dev/2.jpg",
        "/projects/images/dev/3.jpg",
        "/projects/images/dev/4.jpg",
        "/projects/images/dev/5.jpg",
        "/projects/images/dev/6.jpg",
        "/projects/images/dev/7.jpg",
        "/projects/images/dev/8.jpg",
        "/projects/images/dev/9.jpg",
        "/projects/images/dev/10.jpg",
        "/projects/images/dev/11.jpg",
        "/projects/images/dev/12.jpg",
      ],
      image: "/projects/profile/dev-chalanges.jpg",
      title: "devChallenges",
      link: "https://github.com/azateser/devChallenges",
      description: "devchallenges.io",
    },
    {
      id: 5,
      images: [
        "/projects/images/write-game/cover.png",
        "/projects/images/write-game/1.png",
        "/projects/images/write-game/2.png",
        "/projects/images/write-game/3.png",
        "/projects/images/write-game/4.png",
      ],
      image: "/projects/profile/react_native.webp",
      title: "write-typing-game",
      link: "https://github.com/azateser/write-typing-game",
      demoLink: "https://azateser.github.io/write-typing-game/build/index.html",
      description: "React JS",
    },
    {
      id: 6,
      images: [
        "/projects/images/fiverr/cover.png",
        "/projects/images/fiverr/1.png",
        "/projects/images/fiverr/2.png",
        "/projects/images/fiverr/3.png",
        "/projects/images/fiverr/4.png",
        "/projects/images/fiverr/5.png",
        "/projects/images/fiverr/6.png",
      ],
      image: "/projects/profile/react_js.webp",
      title: "fiverr-ui-clone",
      link: "https://github.com/azateser/fiverr-ui-clone",
      demoLink: "https://azateser.github.io/fiverr-ui-clone/dist/",
      description: "React JS",
    },
  ];

  return (
    <div className="font-inter mt-80 z-20" id="projects">
      <Title title={window.innerWidth < 1200 ? "Projects" : "Github Projects"} />
      <div className="flex items-center gap-4 justify-center -mt-80">
        <ServiceItem title="Web Projects" />
        <ServiceItem title="Mobile Projects" />
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 md:grid-cols-2 justify-center max-w-default m-auto xl:px-0 px-6 mt-12">
        {proects.map((project) => (
          <ProjectItem
            key={project.id}
            images={project.images}
            title={project.title}
            description={project.description}
            profile={project.image}
            link={project.link}
            demoLink={project.demoLink}
          />
        ))}
      </div>
    </div>
  );
};

export default GithubProjects;
