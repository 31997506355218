import React from 'react'

import SvaypLeftMobilePhone from "../../../../assets/images/svayp/svayp_left.png"
import SvaypRightMobilePhone from "../../../../assets/images/svayp/svayp_right.png"

import SvaypLogo from "../../../../assets/images/svayp/svayp-logo.svg"

import AppStoreIcon from "../../../../assets/icons/app_store.svg"
import PlayStoreIcon from "../../../../assets/icons/play_store.svg"

import { motion } from 'framer-motion';


const Svayp = () => {
  return (
    <div className='mt-36 relative opacity-80'>
      <div className='flex flex-col w-full items-center justify-center text-center'>
        <motion.img  initial={{ opacity: 0, translateY: "0px"}}  whileInView={{ opacity: 1,translateY: "24px" }} transition={{duration: .7, delay: .5}} className='w-[16rem]' src={SvaypLogo} alt="" />
        <motion.div initial={{ opacity: 0, translateY: "24px"}}  whileInView={{ opacity: 1,translateY: "0px" }} transition={{duration: .7, delay: .5}} style={{marginTop: 70}}>Share your views on various topics with <br /><b>SVAYP - the ultimate public opinion platform</b>.<br /> Download our mobile app and get accurate insights while <br /> joining the conversation with like-minded individuals.</motion.div>
        <div className='flex gap-24 mt-12'>
          <a href='https://apps.apple.com/us/app/svayp/id6447109874?l=us' target='_blank' rel="noreferrer"><motion.img  initial={{ opacity: 0, translateX: "0px"}}  whileInView={{ opacity: 1,translateX: "-12px" }} transition={{duration: .7, delay: .7}} className="transition-all cursor-pointer w-40 hover:scale-110 duration-700 opacity-80 hover:opacity-100" src={AppStoreIcon} /></a>
          <a href='https://play.google.com/store/apps/details?id=com.svayp.svayp' target='_blank' rel="noreferrer"><motion.img  initial={{ opacity: 0, translateX: "0px"}}  whileInView={{ opacity: 1,translateX: "+12px" }} transition={{duration: .7, delay: .7 }} className="transition-all cursor-pointer w-40 hover:scale-110 duration-700 opacity-80 hover:opacity-100" src={PlayStoreIcon} /></a>
        </div>
        <motion.div initial={{ opacity: 0, translateY: "-12px"}}  whileInView={{ opacity: 1,translateY: "0px" }} transition={{duration: .7, delay: 1.5}} style={{marginTop: 70}}>I coded the designs created in Adobe XD for the <br /> Dashboard and Homepage at SVAYP Company <br /> using ReactJS  and various packages. I collaborated with my team to add <br /> new features and improvements by exchanging ideas.</motion.div>
      </div>
        <motion.img  initial={{ opacity: 0, translateX: "0px"}}  viewport={{ margin: "-320px" }}  whileInView={{ opacity: 1,translateX: "120px" }} transition={{duration: .7}} className='xl:block hidden w-[20rem] absolute top-0 left-0' src={SvaypRightMobilePhone} alt="" />
        <motion.img  initial={{ opacity: 0, translateX: "0px"}}  viewport={{ margin: "-320px" }}  whileInView={{ opacity: 1,translateX: "-100px" }} transition={{duration: .7}} className='xl:block hidden w-[20rem] absolute top-0 right-0' src={SvaypLeftMobilePhone} alt="" />
    </div>
  )
}

export default Svayp