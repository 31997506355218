import React from "react";

const Title = ({ title, children }) => {
  return (
    <div className="relative w-full h-[500px] flex flex-col items-center -z-10">
      <div
        className="flex drop-shadow-textShadow flex-col lg:text-6xl uppercase mb-12 md:text-3xl transition-all text-6xl w-max text-white font-inter z-10 select-none  font-black text-center mt-16 mix-blend-overlay opacity-80"
        style={{ textShadow: "0px 0px 0px #ddd7b0" }}
      >
        {title}
      </div>
      <div
        style={{
          backgroundColor: "rgb(18, 17, 32)",
          height: "800px",
          width: "100%",
          opacity: 1,
          overflow: "hidden",
          position: "absolute",
          zIndex: -999,
          left: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            opacity: 1,
            height: "349px",
            left: "calc(50.069444444444464% - 1254px / 2)",
            overflow: "visible",
            position: "absolute",
            top: "calc(49.83660130718957% - 349px / 2)",
            width: "1254px",
          }}
        >
          <div
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, hsl(263, 82%, 35%) 0%, rgba(87, 57, 53, 0) 100%)",
              filter: "blur(64px)",
              WebkitFilter: "blur(64px)",
              opacity: "0.9",
              bottom: 0,
              flex: "none",
              left: 0,
              overflow: "visible",
              position: "absolute",
              top: 0,
              width: "838px",
              height: "349px",
            }}
          />
          <div
            className="framer-inag7a"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, hsl(11, 32%, 44%) 0%, rgba(87, 57, 53, 0) 100%)",
              filter: "blur(64px)",
              WebkitFilter: "blur(64px)",
              opacity: "0.95",
              bottom: 0,
              flex: "none",
              overflow: "visible",
              position: "absolute",
              right: 0,
              top: 0,
              width: "864px",
              height: "349px",
            }}
          />
          <div
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, hsl(243, 50%, 48%) 0%, rgba(87, 57, 53, 0) 100%)",
              filter: "blur(64px)",
              WebkitFilter: "blur(64px)",
              opacity: "0.7",
              flex: "none",
              height: "295px",
              left: "calc(49.9202551834131% - 324px / 2)",
              overflow: "visible",
              position: "absolute",
              top: "calc(49.85673352435532% - 295px / 2)",
              width: "324px",
            }}
          />
        </div>
      </div>

      <div className="z-20">{children}</div>
    </div>
  );
};

export default Title;
