import React from 'react'
import Header from './components/Header/Header'
import MeSection from './components/Sections/Me/Me'
import Experiences from './components/Sections/Experiences/Experiences'
import Projects from './components/Sections/Projects/Projects'
import Contact from './components/Sections/Contact/Contact'
import Footer from './components/Footer/Footer'
import GithubProjects from './components/Sections/Github/Github'

const App = () => {
  return (
    <>
    <Header />
    <MeSection />
    <Experiences />
    <Projects />
    <GithubProjects />
    <Contact />
    <Footer />
    </>
  )
}

export default App