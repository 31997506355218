import React from "react";

const ServiceItem = ({title}) => {
  return (
    <div className="flex items-center justify-between gap-2 bg-gradient-to-r from-[#82828a33] to-[#82828a1a] shadow-service rounded-full border-[1px] font-inter text-[#e0e0e0] text-sm border-[#ffffff21] backdrop-blur-xl px-4 py-2">
      <svg
        width="12"
        height="12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="svg-1106965380_707"
      >
        <path
          d="M11.708.365A1.048 1.048 0 0 0 10.17.31a34.07 34.07 0 0 0-1.955 2.178A51.428 51.428 0 0 0 6.41 4.774a47.728 47.728 0 0 0-2.132 3.143L1.93 4.883c-.352-.45-.97-.558-1.437-.233-.505.354-.643 1.09-.316 1.62 0 0 2.738 4.518 3.104 5.076.29.435.643.653 1.123.653.517 0 .883-.218 1.21-.803.518-.952 1.755-3.266 2.764-4.804a45.035 45.035 0 0 1 1.59-2.272 34.866 34.866 0 0 1 1.703-2.123l.025-.027a1.23 1.23 0 0 0 .012-1.606Z"
          fill="url(#svg-1106965380_707_a)"
        ></path>
        <defs>
          <linearGradient
            id="svg-1106965380_707_a"
            x1="-22"
            y1="6"
            x2="41.5"
            y2="-4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B084FF"></stop>
            <stop offset="1" stop-color="#F38DFA"></stop>
          </linearGradient>
        </defs>
      </svg>
      {title}
    </div>
  );
};

export default ServiceItem;
