import React from "react";
import Title from "../../Other/Title";
import Avatar from "../../../assets/images/avatar.png";
import PenContactSVG from "../../../assets/icons/pen";
import { FaLinkedin, FaGithub, FaMedium, FaFigma } from "react-icons/fa";

const Contact = () => {
  const socialLinks = [
    {
      id: 1,
      name: "Linkedin",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com/in/azateser",
      mobileShow: true,
    },
    {
      id: 2,
      name: "Github",
      icon: <FaGithub />,
      link: "https://github.com/azateser",
      mobileShow: true,
    },
    {
      id: 3,
      name: "Medium",
      icon: <FaMedium />,
      link: "https://medium.com/@azatesser",
      mobileShow: false,
    },
    {
      id: 4,
      name: "Figma",
      icon: <FaFigma />,
      link: "",
      mobileShow: false,
    },
  ];

  return (
    <div className="font-inter mt-52" id="contact">
      <Title title="Contact" />

      <div className="-mt-80 m-auto z-20 lg:flex justify-start mb-20 relative overflow-hidden group lg:max-w-[860px]  transition-all duration-700 shadow-default hover:drop-shadow-glow lg:p-12 p-8 hover:border-[rgb(116,56,255)] bg-[rgba(7,6,14,0.6)] hover:backdrop-blur-none hover:bg-[rgba(7,6,14,0.9)] rounded-lg backdrop-blur-xl border-[rgba(67,65,85,0.25)] border-[0.5px]">
        <div className="lg:w-3/5">
          <div className="flex items-center gap-6">
            <div className="lg:w-10 w-14 relative">
              <img className="lg:w-10 w-14" src={Avatar} alt="Azat ESER" />
              <img
                className=" group-hover:scale-[1.7] transition-all duration-1000 top-0 left-0  absolute"
                src="https://framerusercontent.com/images/jXNTYW9Hl33zlKD406oHIUUfROI.png"
                alt=""
              />
            </div>
            <div className="group-hover:translate-x-2 transition-all duration-1000 flex items-center gap-2 bg-[linear-gradient(rgba(130,130,138,0.2)0%,rgba(130,130,138,0.14)100%)] border-[rgba(255,255,255,0.13)] border backdrop-blur-xl rounded-full lg:text-xs text-base font-medium text-[rgba(245,245,245,0.8)] px-4 py-2">
              <div className=" w-[6px] h-[6px] border-[rgb(108,219,110)] rounded-full border  bg-[rgb(10,143,13)]"></div>
              Available for work
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="text-lg font-semibold">
              Drop me a line or say 👋 on social networks{" "}
            </div>
            <div className="text-[rgba(255,255,255,0.4)] mt-6 text-sm lg:w-3/4">
              If you're a frontend developer seeking assistance or a startup
              eager to enhance your user interface, I'm enthusiastic about
              discussing your project 💜
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:align-bottom align-middle lg:justify-end justify-center lg:mt-0 mt-6">
          <div className="flex items-center lg:gap-4 gap-2 lg:justify-normal justify-center">
            {socialLinks.map((item) => (
              <li
                key={item.id}
                className={`lg:flex list-none text-2xl items-center justify-center w-12 h-12 opacity-50 hover:opacity-100 cursor-pointer transition-all rounded-md hover:bg-[#201f30]`}
              >
                <a href={item.link}>{item.icon}</a>
              </li>
            ))}
          </div>
          <a
            href="mailto:azatesser@gmail.com"
            className="bg-[rgb(32,31,47)] group-hover:bg-primary transition-all font-semibold text-sm text-center py-5 lg:mt-4 mt-2 rounded-lg"
          >
            azatesser@gmail.com
          </a>
        </div>

        <PenContactSVG className="-z-10 lg:-top-6 -top-20 lg:-right-[6.5rem] -right-32 absolute  group-hover:invert group-hover:opacity-30 transition-all" />
      </div>
    </div>
  );
};

export default Contact;
