import React, {useEffect, useState} from "react";
import Logo from "../../assets/Logo";
import { FaLinkedin, FaGithub, FaMedium, FaFigma } from "react-icons/fa";
import "./header.css";

import useWidthWindow from "../../utils/useWidthWindow";
import { motion } from 'framer-motion';

const mailAddress = "azatesser@gmail.com"

const socialLinks = [
  {
    id: 1,
    name: "Linkedin",
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/in/azateser",
    mobileShow: true,
  },
  {
    id: 2,
    name: "Github",
    icon: <FaGithub />,
    link: "https://github.com/azateser",
    mobileShow: true,
  },
  {
    id: 3,
    name: "Medium",
    icon: <FaMedium />,
    link: "https://medium.com/@azatesser",
    mobileShow: false,
  },
  {
    id: 4,
    name: "Figma",
    icon: <FaFigma />,
    link: "",
    mobileShow: false,
  },
];

const navLinks = [
    {
        id: 1,
        name: "Projects",
        link: "#projects",
    },
    {
        id: 2,
        name: "Experiences",
        link: "#experiences",
    },
    {
        id: 3,
        name: "About Me",
        link: "#contact",
    },
    {
        id: 4,
        name: "Skills",
        link: "#skills",
    }
    ];

const Header = () => {
  const width = useWidthWindow();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClasses = [
    isScrolled ? "fixed xl:min-w-default min-w-full xl:h-20 h-10 m-auto top-0 border-[0.5px] border-t-0 min-h-20 backdrop-blur-0 z-50 xl:rounded-b-lg rounded-b-none left-1/2 bg-[rgba(15,14,28,0.9)] border-[rgba(67,65,85,0.25)] shadow-header transform -translate-x-1/2 z-50" : ""
  ];
  

  return (<>
{isScrolled && <div className="xl:h-20 h-[81px]"></div>}
    <motion.header id="header"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ delay: 1 }}
    className={headerClasses.join(" ") + "h-[54px] max-w-default md:px-8 px-6 m-auto flex items-center justify-between py-10"}
    >
      <div className="flex items-center md:gap-6 gap-4">
        <Logo color="#fff" width={width < 768 ? 36 : 56} height="auto" />
        <ul className="flex items-center md:gap-2 gap-0 md:text-3xl text-2xl text-[#bdbdbd]">
          {socialLinks.map((item) => (
            <li key={item.id} className={`lg:flex ${item.mobileShow ? 'flex' : 'hidden'} items-center justify-center w-12 h-12 opacity-50 hover:opacity-100 cursor-pointer transition-all rounded-md hover:bg-[#201f30]`}>
              <a href={item.link}>{item.icon}</a>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <ul className="flex items-center justify-center">
            {navLinks.map((item) => (
                <a href={item.link} key={item.id} className="lg:block hidden text-sm text-textColor opacity-50 hover:opacity-100 rounded-md hover:bg-[#201f30] cursor-pointer px-5 py-3 transition-all">{item.name}</a>
            ))}
            <li className=" cursor-pointer md:scale-100 scale-[.85] md:translate-x-0 translate-x-5"><button className="border-gradient group-hover:border-gradient before:transition-animation relative inline-flex items-center rounded-lg border-2 border-solid border-transparent px-5 py-3 md:text-sm text-xs font-medium text-white before:absolute before:-inset-4 before:z-[-1] before:rounded-lg before:bg-gradient-to-r before:from-dark before:via-secondary before:to-tertiary before:opacity-0 before:blur-sm hover:before:opacity-75 hover:before:blur-xl focus:outline-none focus:ring-offset-white focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-75 group"><a href={`mailto:${mailAddress}`} className="opacity-50 group-hover:opacity-100 transition-all duration-1000">{mailAddress}</a></button></li>
        </ul>
      </div>
    </motion.header>
    </>
  );
};

export default Header;
